<template>
  <main id="homeold">
    <!-- Hero Banner -->
    <v-img id="hero-banner" position="top center" :src="require('../assets/img/home/banner-sky.jpg')" light>
      <v-sheet style="height: 100%; margin: 0 -1rem;" color="rgba(0,0,0,0.5)" dark>
        <v-container class="fill-height px-7" fluid>
          <v-row justify="center">
            <v-col cols="12" md="8">
              <h2 class="text-h2 mt-4 mb-6">
                What Is <strong class= "blue--text">M</strong>y <strong class= "blue--text">O</strong>ne <strong class= "blue--text">R</strong>ule? 
              </h2>
              <h4 class="text-h5 mb-1">
                 <strong class="text-h5"><strong class= "blue--text">MOR</strong> is the best rule for a happier, healthier life and a stronger, healthier country, including <strong class= "blue--text">yours</strong>!</strong> 
              </h4>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="8">
              <v-card class="rounded-lg" elevation="4" data-aos="fade-up">
                <v-img :aspect-ratio="16/9" width="100%">
                  <iframe src="https://www.youtube.com/embed/NO8DWU0Nl1s?rel=0" title="Welcome to My One Rule Video" frameborder="0" style="width: 100%; height: 100%" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen=""></iframe>
                </v-img>
              </v-card>
            <v-col cols="12 pb-0">
                <v-row justify="center">
                  <v-col cols="12" md="6">
                    <v-btn class="" color="primary" @click="$vuetify.goTo('#explanation')" data-aos="fade-up" block large>
                      I want a better life
                      <v-icon right dark>
                      mdi-draw
                      </v-icon>
                    </v-btn>
                  </v-col>
                    <v-col cols="12" md="6">
                      <v-btn class="" color="red darken-3" href="https://shop.myonerule.com/" data-aos="fade-up" block large dark>
                        Shop Reminders
                        <v-icon right dark>
                        mdi-cart
                        </v-icon>
                      </v-btn>
                    </v-col>
                </v-row>
              </v-col>
            </v-col>
            </v-row>
        </v-container>
      </v-sheet>
    </v-img>
    
    <!-- Benefit Cards -->
    <v-sheet id="benefits">
      <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
      <v-container class="pb-8" style="max-width: 1280px">
        <!-- Benefits -->
        <v-row justify="center">
          <v-col cols="12">
            <div class="text-h4 font-weight-light my-4">
              Benefits of My One Rule
            </div>
          </v-col>
        </v-row>
        
        <v-row justify="center">
          <!-- Happiness -->
          <v-col class="d-flex" cols="12">
            <v-card class="rounded-lg flex-grow-1" style="overflow: hidden" elevation="4" data-aos="fade-up">
              <v-img :src="require('../assets/img/home/card-happiness.jpg')" :aspect-ratio="16/9" contain>
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5" aria-label="Better relationships card photo loader."></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
              <v-card-text>
                <div class="text-h6 blue--text text--darken-3 mb-0">
                  More Happiness
                </div>
                <div class="text-body-1 black--text mb-0">
                  <strong class="text-h6">MOR</strong> helps achieve better solutions, better cures, better overall results.<br>
                  <strong class="text-h6">MOR</strong> cures more problems than ten million laws, millions of police, thousands of prisons and millions of doctors.
                </div>
                
                <v-row justify="black--text center">
                  <v-col cols="6">
                    <ul class="text-body-1 text-center pa-0" style="list-style: none">
                      <li>With My One Rule you have more:</li>
                      <strong>
                      <li>Peace</li>
                      <li>Respect</li>
                      <li>Friends</li>
                      <li>Kindness</li>
                      <li>Helpfulness</li>
                      </strong>
                    </ul>
                  </v-col>
                  <v-col cols="6">
                    <ul class="text-body-1 text-center pa-0" style="list-style: none">
                      <li>With My One Rule you have less:</li>
                      <strong>
                      <li>Regrets</li>
                      <li>Anxiety</li>
                      <li>Stress</li>
                      <li>Fighting</li>
                      <li>Selfishness</li>
                      </strong>
                    </ul>
                  </v-col>
                </v-row>
                <strong>My One Rule</strong> is more effective at reducing stress than exercise, alcohol and drugs combined!
              </v-card-text>
              <v-col cols="12 pt-0">
              <v-row justify="center">
                <v-col cols="12" md="4">
                  <v-btn class="" color="primary" @click="$vuetify.goTo('#explanation')" data-aos="fade-up" block large>
                    I want more happiness
                    <v-icon right dark>
                      mdi-draw
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn class="" color="red darken-3" href="https://shop.myonerule.com/" data-aos="fade-up" block large dark>
                    Shop Reminders
                    <v-icon right dark>
                      mdi-cart
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            </v-card>
          </v-col>

          <!-- Relationships -->
          <v-col class="d-flex" cols="12">
            <v-card class="rounded-lg flex-grow-1" style="overflow: hidden" elevation="4" data-aos="fade-up">
              <v-img :src="require('../assets/img/home/card-relationships.jpg')" :aspect-ratio="16/9" contain>
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5" aria-label="Relationships card photo loader."></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
              <v-card-text>
                <div class="text-h6 blue--text text--darken-3 mb-0">
                  Better Relationships
                </div>
                <div class="text-body-1 black--text mb-1">
                  <strong class="text-h6">MOR</strong> is the best way to resolve most miscommunications and misunderstandings.
                </div>
                <div class="text-body-1 black--text">
                  <ul class="text-body-1 black--text text-center" style="list-style: none">
                      <strong>
                      <li>Living MOR produces:</li>
                      <li>More Friends</li>
                      <li>Better work relationships</li>
                      <li>More loving families</li>
                      </strong>
                </ul>
                </div>
              </v-card-text>
              <v-col cols="12 pt-0">
              <v-row justify="center">
                <v-col cols="12" md="4">
                  <v-btn class="" color="primary" @click="$vuetify.goTo('#explanation')" data-aos="fade-up" block large>
                    I want better relationships
                    <v-icon right dark>
                      mdi-draw
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn class="" color="red darken-3" href="https://shop.myonerule.com/" data-aos="fade-up" block large dark>
                    Shop Reminders
                    <v-icon right dark>
                      mdi-cart
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            </v-card>
          </v-col>

          <!-- Communities -->
          <v-col class="d-flex" cols="12">
            <v-card class="rounded-lg flex-grow-1" style="overflow: hidden" elevation="4" data-aos="fade-up">
              <v-img :src="require('../assets/img/home/card-communities.jpg')" :aspect-ratio="16/9" contain>
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5" aria-label="Communities card photo loader."></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
              <v-card-text>
                <div class="text-h6 blue--text text--darken-3 mb-0">
                  Safer Communities
                </div>
                <div class="text-body-1 black--text mb-0 pa-0">
                  <strong class="text-h6">MOR</strong> is the best way to build better communities.
                </div>
                <div class="text-body-1 black--text pa-0">
                <v-row justify="center">
                  <v-col cols="6 pa-0">
                    <ul class="text-body-1 text-center" style="list-style: none">
                      <li>With My One Rule you have more:</li>
                      <li>Safety</li>
                      <li>Happiness</li>
                      <li>Respect</li>
                      <li>Kindness</li>
                      <li>Peace</li>
                    </ul>
                  </v-col>
                  <v-col cols="6 pa-0">
                    <ul class="text-body-1 text-center" style="list-style: none">
                      <li>With My One Rule you have less:</li>
                      <li>Lying</li>
                      <li><a href="/racism">Racism</a></li>
                      <li><a href="/bullying">Bullying</a></li>
                      <li>Stealing</li>
                      <li>Murder</li>
                    </ul>
                  </v-col>
                </v-row>
                </div>
              </v-card-text>
              <v-col cols="12 pt-0">
              <v-row justify="center">
                <v-col cols="12" md="4">
                  <v-btn class="" color="primary" @click="$vuetify.goTo('#explanation')" data-aos="fade-up" block large>
                    I want safer communities
                    <v-icon right dark>
                      mdi-draw
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn class="" color="red darken-3" href="https://shop.myonerule.com/" data-aos="fade-up" block large dark>
                    Shop Reminders
                    <v-icon right dark>
                      mdi-cart
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            </v-card>
          </v-col>

          <!-- Better Life -->
          <v-col class="d-flex" cols="12">
            <v-card class="rounded-lg flex-grow-1" style="overflow: hidden" elevation="4" data-aos="fade-up">
              <v-img :src="require('../assets/img/home/card-better-life.jpg')" :aspect-ratio="16/9" contain>
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5" aria-label="Better life card photo loader."></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
              <v-card-text>
                <div class="text-h6 blue--text text--darken-3 mb-0">
                  A Better Life
                </div>
                <div class="text-body-1 black--text">
                  <strong>Sometimes a better life means more of some things and less of others.</strong><br> 
                  Imagine how the news would change if we lived My One Rule.
                </div>
                <v-row class="black--text" justify="center">
                  <v-col cols="6">
                    <p class="text-body-1">
                    With My One Rule there is more:
                    </p>
                    <ul class="text-body-1" style="list-style: none">
                      <strong>
                      <li>Kindness</li>
                      <li>Love</li>
                      <li>Respect</li>
                      <li>Peace</li>
                      <li>Friends</li>
                      <li>Better Help</li>
                      </strong>
                      <v-btn class="" color="primary" @click="improveDialog = true" large>
                      See List
                      </v-btn>
                    </ul>
                  </v-col>
                  <v-col cols="6">
                    <p class="text-body-1">
                    With My One Rule there is less:
                    </p>
                    <ul class="text-body-1" style="list-style: none">
                      <strong>
                      <li><a href="/racism">Racism</a></li>
                      <li><a href="/bullying">Bullying</a></li>
                      <li>Murder</li>
                      <li>Lying</li>
                      <li>Stealing</li>
                      <li>Meanness</li>
                      </strong>
                      <v-btn class="" color="red darken-3" @click="reduceDialog = true" large dark>
                      See List
                      </v-btn>
                    </ul>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-col cols="12 pt-0">
              <v-row justify="center">
                <v-col cols="12" md="4">
                  <v-btn class="" color="primary" @click="$vuetify.goTo('#explanation')" data-aos="fade-up" block large>
                    I want a better life
                    <v-icon right dark>
                      mdi-draw
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn class="" color="red darken-3" href="https://shop.myonerule.com/" data-aos="fade-up" block large dark>
                    Shop Reminders
                    <v-icon right dark>
                      mdi-cart
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            </v-card>
          </v-col>

          <!-- Reduce Modal -->
          <v-dialog v-model="reduceDialog" max-width="350px" scrollable>
            <v-card class="rounded-lg d-flex flex-column flex-grow-1" elevation="4">
              <v-card-title>
                <div class="text-h6 red--text text--darken-3">
                  My One Rule Can Reduce:
                </div>
              </v-card-title>
              <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
              <v-card-text class="black--text" style="height: 300px">
                <v-row justify="center">
                  <v-col cols="6">
                    <ul class="text-body-1 text-center pa-0" style="list-style: none">
                      <li><a href="/bullying">Bullying</a></li>
                      <li>Cancel Culture</li>
                      <li><a href="/racism">Racism</a></li>
                      <li>Hunger</li>
                      <li>Fighting</li>
                      <li>Cheating</li>
                      <li>Lying</li>
                      <li>Selfishness</li>
                      <li>Rape</li>
                      <li>Murder</li>
                      <li>War</li>
                      <li>Regret</li>
                      <li>Coveting</li>
                      <li>Hate</li>
                      <li>Abuse</li>
                      
                    </ul>
                  </v-col>
                  <v-col cols="6">
                    <ul class="text-body-1 text-center pa-0" style="list-style: none">
                      <li>Wasted Taxes</li>
                      <li>Hopelessness</li>
                      <li>Community Fears</li>
                      <li>Riots</li>
                      <li>Stealing</li>
                      <li>Adultery</li>
                      <li>Violence</li>
                      <li>School Shootings</li>
                      <li>Slavery</li>
                      <li>Hate Speech</li>
                      <li>National Debt</li>
                      <li>Nastiness</li>
                      <li>Mean People</li>
                      <li>Voter Fraud</li>
                    </ul>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col>
                    <p class="text-body-1 mb-0">
                      <strong>And about a million other things!</strong>
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="reduceDialog = false" text>
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Improve -->
          <v-dialog v-model="improveDialog" max-width="350px" scrollable>
            <v-card class="rounded-lg d-flex flex-column flex-grow-1" elevation="4">
              <v-card-title>
                <div class="text-h6 blue--text text--darken-3">
                  My One Rule Can Improve:
                </div>
              </v-card-title>
              <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
              <v-card-text class="black--text" style="height: 300px">
                <v-row justify="center">
                  <v-col cols="6">
                    <ul class="text-body-1 text-center pa-0" style="list-style: none">
                      <li>Kindness</li>
                      <li>Goodness</li>
                      <li>Helpfulness</li>
                      <li>Health</li>
                      <li>Respect</li>
                      <li>Safety</li>
                      <li>Hope</li>
                      <li>Love</li>
                    </ul>
                  </v-col>
                  <v-col cols="6">
                    <ul class="text-body-1 text-center pa-0" style="list-style: none">
                      <li>Peace</li>
                      <li>Love</li>
                      <li>Joy</li>
                      <li>Relationships</li>
                      <li>Families</li>
                      <li>Communities</li>
                      <li>School</li>
                      <li>Work</li>
                    </ul>
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col>
                    <p class="text-body-1 mb-0">
                      <strong>And about a million other things!</strong>
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="improveDialog = false" text>
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- It's not about politics -->
          <v-col class="d-flex" cols="12">
            <v-card class="rounded-lg flex-grow-1" style="overflow: hidden" elevation="4" data-aos="fade-up">
              <v-img :src="require('../assets/img/fighting.jpg')" :aspect-ratio="16/9" contain>
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5" aria-label="Polotics card photo loader."></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
              <v-card-text>
                <div class="text-h6 blue--text text--darken-3 mb-3">
                  It is not about Left or Right
                </div>
                <div class="text-body-1 black--text">
                  <strong class= "text-h6">It is not about politics at all.</strong><br>
                  <strong>It is about people</strong> and doing what is right!
                </div>
              </v-card-text>
              <v-col cols="12 pt-0">
              <v-row justify="center">
                <v-col cols="12" md="4">
                  <v-btn class="" color="primary" @click="$vuetify.goTo('#explanation')" data-aos="fade-up" block large>
                    I can do it
                    <v-icon right dark>
                      mdi-draw
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn class="" color="red darken-3" href="https://shop.myonerule.com/" data-aos="fade-up" block large dark>
                    Shop Reminders
                    <v-icon right dark>
                      mdi-cart
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            </v-card>
          </v-col>

          <!-- Who Can Do it -->
          <v-col class="d-flex" cols="12">
            <v-card class="rounded-lg flex-grow-1" style="overflow: hidden" elevation="4" data-aos="fade-up">
              <v-img :src="require('../assets/img/home/card-everyone.jpg')" :aspect-ratio="16/9" contain>
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5" aria-label="Who can do it card photo loader."></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
              <v-card-text>
                <div class="text-h6 blue--text text--darken-3 mb-3">
                  Who Can Do It?
                </div>
                <div class="text-body-1 black--text">
                  <strong class= "text-h6">You, and everyone else.</strong><br>
                  <strong>Living My One Rule</strong> is low risk, extremely high reward.
                </div>
              </v-card-text>
              <v-col cols="12 pt-0">
              <v-row justify="center">
                <v-col cols="12" md="4">
                  <v-btn class="" color="primary" @click="$vuetify.goTo('#explanation')" data-aos="fade-up" block large>
                    I can do it
                    <v-icon right dark>
                      mdi-draw
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn class="" color="red darken-3" href="https://shop.myonerule.com/" data-aos="fade-up" block large dark>
                    Shop Reminders
                    <v-icon right dark>
                      mdi-cart
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            </v-card>
          </v-col>

           <v-col class="d-flex" cols="12">
            <v-card class="rounded-lg flex-grow-1 ma-0 pa-0" color="red" style="overflow: hidden" elevation="4" data-aos="fade-up">
              <v-card-text>
                <div class="text-h5 white--text text--darken-3 mb-3">
                 I want MOR, where I live!
              </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col class="d-flex" cols="12">
            <v-card class="rounded-lg flex-grow-1 ma-0 pa-0" style="overflow: hidden" elevation="4" data-aos="fade-up">
              <v-card-text>
                <div class="text-h5 blue--text text--darken-3 mb-3">
                  I want MOR, in my school!
              </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col class="d-flex" cols="12">
            <v-card class="rounded-lg flex-grow-1 ma-0 pa-0" color="blue" style="overflow: hidden" elevation="4" data-aos="fade-up">
              <v-card-text>
                <div class="text-h5 white--text text--darken-3 mb-3">
                  I want MOR, at my work!
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- So what do you want to do? -->
          <v-col class="d-flex" cols="12">
            <v-card class="rounded-lg flex-grow-1" style="overflow: hidden" elevation="4" data-aos="fade-up">
              <v-img :src="require('../assets/img/home/card-why-not.jpg')" :aspect-ratio="16/9" contain>
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular indeterminate color="grey lighten-5" aria-label="What do you want to do card photo loader."></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
              <v-card-text>
                <div class="text-h6 blue--text text--darken-3 mb-3">
                  You are here for a reason! <br>
                  You matter and you care.
                </div>
                <div class="text-body-1 black--text">
                  Don't wait for someone else to do something <strong>only you can do.</strong><br>
                  If you don't, who will?<br>
                   We can solve and cure a world of problems.<br>
                  <strong>NOW IS THE TIME</strong><br>
                  <strong>THIS IS THE PLACE</strong><br>
                </div>
            </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <v-col class="d-flex" cols="12">
            <v-card class="rounded-lg flex-grow-1" color="blue" style="overflow: hidden" elevation="4" data-aos="fade-up">
              <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
              <v-card-text>
                <div class="text-h5 white--text text--darken-3 mb-0">
                   This is what we have to do.<br>
                </div>
                <div class="text-h5 white--text">
                  <strong class="text-h4">Treat others the way you want to be treated</strong><br>
                  1. Live It! ⠀⠀⠀⠀⠀2. Share It!<br>
                  3. Make it the Standard!<br>
                  <strong>Let's do it, for <strong class= "black--text">ourselves</strong> and <strong class= "black--text">those we love!</strong></strong>
                </div>
            <v-col cols="12 pa-0">
              <v-row justify="center">
                <v-col cols="12" md="4">
                  <v-btn class="" color="primary" @click="$vuetify.goTo('#pledge')" data-aos="fade-up" block large>
                    Let's do it
                    <v-icon right dark>
                      mdi-draw
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn class="" color="red darken-3" href="https://shop.myonerule.com/" data-aos="fade-up" block large dark>
                    Shop Reminders
                    <v-icon right dark>
                      mdi-cart
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            </v-card-text>
            </v-card>
          </v-col>

    <!-- Pledge Section -->
    <v-sheet id="pledge-form" color="grey lighten-3">
      <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
      <v-container class="py-8" style="max-width: 1280px" fill-height>
        <v-row justify="center">
          <v-col class="d-flex" cols="12" md="12" data-aos="fade-up">
            <!-- Video -->
            <v-card class="d-flex flex-column justify-start rounded-lg flex-grow-1" style="overflow: hidden" color="grey lighten-4" elevation="4" data-aos="fade-up" light>
              <!-- Video -->
              <div>
                <v-img :aspect-ratio="16/9" width="100%">
                  <iframe src="https://www.youtube-nocookie.com/embed/QEQoHf1rtiw?rel=0" title="Live My One Rule Video" frameborder="0" style="width: 100%; height: 100%" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen=""></iframe>
                </v-img>
              </div>
              <v-sheet style="height: 1px" color="grey"></v-sheet>
              <v-card-text class="d-flex flex-column justify-center flex-grow-1" id="explanation">
                <div class="text-h6 blue--text text--darken-3">
                  My One Rule
                </div>
                <div class="text-h5 blue--text font-weight-bold text-decoration-underline mb-2 text--darken-3">
                  Treat others the way you want to be treated.
                </div>
                <div class="text-h5 red--text mb-4" v-animate-css="animationInfinite">
                  <strong>
                  Join us now!<br>
                  Put your name on the map!
                  </strong>
                </div>
                <div class="text-h5 font-weight-bold mb-2" v-animate-css="animationInfinite3">
                  Without My One Rule and <strong class="blue--text">YOUR</strong> help<br>
                  our future is very shaky!
                </div>
                <div class="" v-animate-css="animationInfinite2">
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-img :src="require('../assets/img/globe-image.png')" :max-height="500"
                  max-width="500" contain>
                  <template v-slot:placeholder>
                    <v-progress-circular indeterminate color="grey lighten-5" aria-label="Globe image loader."></v-progress-circular>
                  </template>
                  </v-img>
                </v-row>
              </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col id="joinold" class="d-flex" cols="12" md="12" data-aos="fade-up">
            <PledgeForm/>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <!-- Pledge Map-->
    <v-sheet id="map-view">
      <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
      <v-container style="max-witdh: 1280px">
        <v-row justify="center">
          <v-col cols="12">
            <div class="text-h4 font-weight-light my-4">
              See where people are making things better. 
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
      <v-container class="pa-0" style="height: 500px" fluid fill-height>
        <v-row align="stretch" style="height: 100%" no-gutters>
          <v-col class="d-flex" cols="12" data-aos="fade">
            <PledgeMap/>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <!-- Reminders -->
    <v-sheet id="reminders">
      <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
      <v-container class="pb-8" style="max-width: 1280px">
        <v-row justify="center">
          <v-col cols="12" md="8">
            <div class="text-h4 font-weight-light black--text mt-4 mb-7">
              Reminders to Live My One Rule
            </div>
            <div class="text-subtitle-1 black--text mb-4">
              Life happens fast, it is easy to forget how you want to live. 
              But with visible, powerful reminders you can make better decisions and be the person you want to be. 
            </div>
            <div class="text-subtitle-1 black--text mb-5">
              They will also improve your life and help you share <strong>MOR</strong> with others.
            </div>
            <v-card class="rounded-lg mb-6" flat>
              <v-img :aspect-ratio="3.2">
                <video autoplay="autoplay controls" loop="loop" muted="muted" style="width: 100%">
                  <source :src="require('../assets/video/product/wristband.mp4')" type="video/mp4"> Your browser does not support the video tag. 
                </video>
              </v-img>
              <v-card-actions>
                <v-btn class="mx-auto px-8" color="red darken-3" href="https://shop.myonerule.com/" data-aos="fade-up" large dark>
                  Shop Reminders
                  <v-icon right dark>
                    mdi-cart
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <!-- Testimonials -->
    <v-sheet id="testimonials" color="grey lighten-3">
      <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
      <v-container style="max-width: 1280px;">
        <v-row justify="center">
          <v-col cols="12">
            <div class="text-h4 font-weight-light my-4">
              Testimonials
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="12">
            <v-carousel height="auto" hide-delimiters light>
              <v-carousel-item v-for="(t, i) in testimonials" :key="'testimonial-' + i">
                <v-row class="fill-height" justify="center" align="center">
                  <v-col cols="10">
                    <p class="text-body-1 font-italic">{{ t.text }}</p>
                    <h6 class="text-h6">{{ t.name }}</h6>
                  </v-col>
                </v-row>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <!-- Step Cards -->
    <v-img id="step-cards" position="top center" :src="require('../assets/img/home/banner-sky.jpg')" light>
      <v-sheet style="height: 100%; margin: 0 -1rem;" color="rgba(0,0,0,0.6)">
        <v-container class="fill-height px-7" style="max-width: 1280px" fluid>
          <!-- Benefits -->
          <v-row justify="center">
            <v-col cols="12">
              <div class="text-h4 font-weight-light my-4 white--text">
                The Three Steps
              </div>
            </v-col>
          </v-row>
          <v-row justify="center">
            <!-- Live -->
            <v-col class="d-flex" cols="12">
              <v-card class="d-flex flex-column rounded-lg flex-grow-1" style="overflow: hidden" elevation="4" data-aos="fade-up">
                <div>
                  <v-img :aspect-ratio="16/9" width="100%">
                    <iframe src="https://www.youtube.com/embed/eB8obC9wyuM?rel=0" title="Living My One Rule Video" frameborder="0" style="width: 100%; height: 100%" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen=""></iframe>
                  </v-img>
                </div>
                <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
                <v-card-text class="flex-grow-1">
                  <div class="text-h6 blue--text text--darken-3 mb-3">
                    Step 1: Live My One Rule
                  </div>
                  <div class="text-body-1 black--text mb-4">
                    <strong>Living My One Rule</strong> means filtering your thoughts and actions to help achieve better outcomes.<br>
                    It’s your commitment to be a better person every day. You’re in control of how you treat others.<br>
                    With My One Rule reminders, you will be a positive influence in your community.
                  </div>
                  <v-btn class="mb-0" color="primary" @click="$router.push({ path: 'about', query: { target: '#steps', step: 0 }})">
                    Learn More
                  </v-btn>
                </v-card-text>
                <v-col cols="12 pt-0">
              <v-row justify="center">
                <v-col cols="12" md="4">
                  <v-btn class="" color="primary" @click="$vuetify.goTo('#join')" data-aos="fade-up" block large>
                    Live My One Rule
                    <v-icon right dark>
                      mdi-draw
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn class="" color="red darken-3" href="https://shop.myonerule.com/" data-aos="fade-up" block large dark>
                    Shop Reminders
                    <v-icon right dark>
                      mdi-cart
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              </v-col>
              </v-card>
            </v-col>
  
            <!-- Teach -->
            <v-col class="d-flex" cols="12">
              <v-card class="d-flex flex-column rounded-lg flex-grow-1" style="overflow: hidden" elevation="4" data-aos="fade-up">
                <div>
                  <v-img :aspect-ratio="16/9" width="100%">
                    <iframe src="https://www.youtube-nocookie.com/embed/D5NMcAmvlHc?rel=0" title="Sharing My One Rule Video" frameborder="0" style="width: 100%; height: 100%" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen=""></iframe>
                  </v-img>
                </div>
                <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
                <v-card-text class="flex-grow-1">
                  <div class="text-h6 blue--text text--darken-3 mb-3">
                    Step 2: Share My One Rule
                  </div>
                  <div class="text-body-1 black--text mb-4">
                    Once you see what My One Rule can do in your life, you’ll naturally want to share it with others.<br>
                    Use conversation starters to open dialogues in your family, friend group, and community.<br> 
                    <br>
                    If you share My One Rule with two people and they each do the same and the sharing continues,<br>
                    The world can change for the better almost immediately.<br>
                    <strong>We can do this together!</strong>
                  </div>
                  <v-btn class="mb-0" color="primary" @click="$router.push({ path: 'about', query: { target: '#steps', step: 1 }})">
                    Learn More
                  </v-btn>
                </v-card-text>
                <v-col cols="12 pt-0">
              <v-row justify="center">
                <v-col cols="12" md="4">
                  <v-btn class="" color="primary" @click="$vuetify.goTo('#join')" data-aos="fade-up" block large>
                    Share My One Rule
                    <v-icon right dark>
                      mdi-draw
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn class="" color="red darken-3" href="https://shop.myonerule.com/" data-aos="fade-up" block large dark>
                    Shop Reminders
                    <v-icon right dark>
                      mdi-cart
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              </v-col>
              </v-card>
            </v-col>
  
            <!-- Make -->
            <v-col class="d-flex" cols="12">
              <v-card class="d-flex flex-column rounded-lg flex-grow-1" style="overflow: hidden" elevation="4" data-aos="fade-up">
                <div>
                  <v-img :aspect-ratio="16/9" width="100%">
                    <iframe src="https://www.youtube-nocookie.com/embed/eg1Uj8K9qII?rel=0" title="Making It The Standard Video" frameborder="0" style="width: 100%; height: 100%" allow="accelerometer; autoplay; encrypted-media; gyroscope;" allowfullscreen=""></iframe>
                  </v-img>
                </div>
                <v-sheet style="height: 1px" color="grey lighten-1"></v-sheet>
                <v-card-text class="flex-grow-1">
                  <div class="text-h6 blue--text text--darken-3 mb-3">
                    Step 3: Make It The Standard
                  </div>
                  <div class="text-body-1 black--text mb-2">
                    Making My One Rule the standard means living <strong>MOR</strong> in every interaction.<br>
                    All families, organizations, schools, politicians, and businesses can benefit from adopting My One Rule.<br>
                    Use reminders to help you and others to make the world better everyday!
                  </div>
                  <v-btn class="mb-0" color="primary" @click="$router.push({ path: 'about', query: { target: '#steps', step: 2 }})">
                    Learn More
                  </v-btn>
                </v-card-text>
              <v-col cols="12 pt-0">
              <v-row justify="center">
                <v-col cols="12" md="4">
                  <v-btn class="" color="primary" @click="$vuetify.goTo('#join')" data-aos="fade-up" block large>
                    Make MOR the standard
                    <v-icon right dark>
                      mdi-draw
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4">
                  <v-btn class="" color="red darken-3" href="https://shop.myonerule.com/" data-aos="fade-up" block large dark>
                    Shop Reminders
                    <v-icon right dark>
                      mdi-cart
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              </v-col>
            </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-img>
  </main>
</template>

<script>
import PledgeForm from "../components/PledgeForm.vue";
import PledgeMap from "../components/PledgeMap.vue";

export default {
  name: 'Home',
  data: () => ({
    reduceDialog: false,
    improveDialog: false,
    
    //Pledge Animation
    animationInfinite: {
      classes: 'pulse',
      duration: 1000,
      iteration: 'infinite'
    }, 
    //Pledge Animation 2
    animationInfinite2: {
      classes: 'shake',
      duration: 1500,
      iteration: 'infinite'
    }, 

    //Pledge Animation 3
    animationInfinite3: {
      classes: 'tada',
      duration: 6000,
      iteration: 'infinite'
    }, 

    // Testimonial entries.
    testimonials: [
      {
        name: "Bill W.",
        text: 
          `Wearing the My One Rule reminders has helped me make better decisions. 
          I have found myself getting less angry and by treating others better, they treat me better.`,
      },
      {
        name: "Pat C.",
        text: `Life isn't fair, but I can be.
              Living My One Rule has improved my relationships and people have noticed.`,
      },
      {
        name: "Frida",
        text: `My one-year old son has a cleft-hand, I am worried he will be bullied. 
              With My One Rule I don't need to be worried.`
      }
    ]
  }),
  components: {
    PledgeForm,
    PledgeMap
  }
}
</script>
